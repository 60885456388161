import MarkdownIt from 'markdown-it'
import modifyToken from 'markdown-it-modify-token'

/**
 * Callback used to cleanup links
 *
 * @param {object} token - Token to parse
 */
function mdTokenModifier (token) {
  // "Secure" links to the outside world
  if (token.type === 'link_open') {
    const rx = new RegExp(`^https?://${window.location.host}(?:/.*)?$`)

    if (!rx.test(token.attrObj.href)) {
      token.attrObj.target = '_blank'
      token.attrObj.rel = 'noreferrer noopener'
    }
  }
}

const md = new MarkdownIt({
  linkify: true,
  typographer: true,
  modifyToken: mdTokenModifier,
})

md.use(modifyToken)

/**
 * Wrapper in global context to render markdown
 *
 * @param   {string} text - Markdown text
 * @returns {string}      HTML version
 */
window.markdown = function (text) {
  return md.render(text)
}

/**
 * Wrapper in global context to render markdown content from an HTML element in
 * another.
 *
 * @param {HTMLElement} sourceElement - Source HTML element
 * @param {HTMLElement} targetElement - Target element
 */
window.renderMD = function (sourceElement, targetElement) {
  const content = targetElement.innerHTML
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
  sourceElement.innerHTML = window.markdown(content)
}
